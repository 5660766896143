<template>
    <a-card>
        <a-page-header title='抖音号视频管理' />
        <a-form-model ref="form" :model="form" v-bind='layout'>
            <a-row type="flex" justify="start">
                <a-col :span="8">
                    <a-form-model-item label="视频标题">
                        <a-input v-model="form.title" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="抖音视频Id">
                        <a-input v-model="form.itemId" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="7">
                    <a-form-model-item label="巨量素材ID">
                        <a-input v-model="form.mid" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="start">
                <a-col :span="8">
                    <a-form-model-item label="抖音昵称">
                        <a-input v-model="form.awemeName" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="抖音号">
                        <a-input v-model="form.awemeId" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="end">
                <a-button @click="handleReset">重置</a-button>
                <a-button style="margin: 0 55px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
            </a-row>
        </a-form-model>

        <a-table style="margin-top: 20px;" :loading="tableLoading" :columns="columns" :data-source="dataList"
            :rowKey="record => record.id" :pagination='false'>
            <div slot="title-slot" slot-scope="text, record">
                <div class="blue-text" @click="handleJump(record)">{{ record.title }}</div>
                <div class="gray-text">抖音视频ID: {{ record.itemId }}</div>
                <div class="gray-text">巨量素材ID: {{ record.mid }}</div>
            </div>
            <div slot="videoCover" slot-scope="text, record" class="img-container">
                <img :src="record.adPosterUrl" alt="视频封面">
            </div>
            <div slot="awemeName" slot-scope="text, record">
                <div>{{ record.awemeName }}</div>
                <div class="gray-text">抖音号: {{ record.awemeId }}</div>
            </div>
        </a-table>

        <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
            @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />

    </a-card>
</template>

<script>
export default {
    data() {
        const columns = [
            {
                title: '视频标题',
                align: 'center',
                width: '30%',
                scopedSlots: { customRender: 'title-slot' },
            },
            {
                title: '视频封面',
                align: 'center',
                width: '11%',
                scopedSlots: { customRender: 'videoCover' },
            },
            {
                title: '抖音昵称/抖音号',
                align: 'center',
                scopedSlots: { customRender: 'awemeName' },
            },
            {
                align: 'center',
                title: '首次记录日期',
                dataIndex: 'ctime',
                customRender(text) {
                    return text || '-'
                }
            },
            {
                align: 'center',
                title: '更新日期',
                dataIndex: 'mtime',
                customRender(text) {
                    return text || '-'
                }
            },
        ]
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                title: ''
            },
            tableLoading: false,
            columns,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            dataList: [],

        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        async getDataList() {
            const _form = this.form
            const params = {
                ..._form,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.tableLoading = true
            const { code, data } = await this.$api.core.materialManage.getAwemeVideoList(params).finally(()=>
            this.tableLoading = false
            )
            if (code == 200) {
                this.dataList = data.list || []
                this.pagination.total = data.total || 0
            } else {
                this.dataList = []
                this.pagination.total = 0
            }
        },
        handleJump(data){
            window.open(data.awemePlayUrl, '_blank')

        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = this.$options.data().form
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
    }
}
</script>

<style scoped lang="less">
.img-container {
    width: 100%;
    height: 80px;
    text-align: center;

    img {
        width: 45px;
        height: 100%;
    }
}

.blue-text {
    color: #509bf8;
    text-decoration: underline;
    cursor: pointer;
}

.gray-text {
    color: #888888;
}
</style>